require('../css/app.scss');

const $ = global.jQuery;

$().ready(() => {
    $('#header-slider .carousel-item').each((i, el) => {
        if ($(el).data('source')) {
            $(el).css('background-image', 'url(\'%s\')'.replace('%s', $(el).data('source')));
        }
    });

    $('#header-slider').carousel('cycle');
});